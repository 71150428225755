<template>
  <div class="body">
    <div style="height: 86vh; margin-top: 7vh; width: 90vw; margin-left: 5vw; position: absolute; overflow: hidden; background-color: #FFFFFF; border-radius: 40px">
      <div style="background-color: #5566FF; width: 600px; height: 110vh; position: absolute; right: -20px; top: -20px; transform: rotate(2deg)"></div>
      <div style="background-color: #3C47B0; width: 400px; bottom: -200px; height: 400px; border-radius: 0px; position: absolute; left: -200px;transform: rotate(45deg); color: #FFFFFF; font-size: 30px; font-weight: normal; text-align: center; line-height: 250px">
        <img src="@/assets/images/login/icon-white-trans.png" width="70px">
      </div>
      <div style="background-color: #5F6FFF; width: 300px; height: 150px; border-radius: 40px; position: absolute; right: -30px; top: -10px; transform: rotate(5deg);"></div>
      <div style="background-color: #3C47B0; width: 650px; top: -160px; height: 200px; border-radius: 50px; position: absolute; right: 0;transform: rotate(-10deg);"></div>
<!--      <div style="background-color: #6675FE; width: 650px; height: 400px; border-radius: 40px; position: absolute; right: -20px; bottom: 0px; transform: rotate(-5deg);"></div>-->
      <div style="background-color: #5599FF; width: 650px; height: 650px; border-radius: 50px; position: absolute; right: -150px; bottom: -340px; transform: rotate(-40deg);"></div>
      <div style="background-color: #5091F2; width: 300px; height: 400px; border-radius: 40px; position: absolute; right: 0px; bottom: -150px; transform: rotate(35deg)"></div>
      <img src="@/assets/images/login/line.png" class="animation-target" style="border-radius: 30px; position: absolute; right: 300px; top: 100px; width: 207px">
      <img src="@/assets/images/login/key.png" class="animation-target" style="border-radius: 30px; position: absolute; right:  94px; top: 450px; width: 413px">
<!--      <div style="background-image: url('/wechat.png'); background-size: 85% 85%;background-position: center; width: 60px; height: 60px; border-radius: 60px; position:absolute; right: 180px; top: 200px"></div>-->
<!--      <div style="background-image: url('/weibo.png'); background-size: 85% 85%;background-position: center; width: 60px; height: 60px; border-radius: 60px; position:absolute; right: 90px; top: 350px"></div>-->
    </div>
    <div style="width: 30%;position: absolute;top: 35%;left: 20%">
<!--      <img src="/sc.png" style="position: absolute; bottom: 120px; opacity: .2; width: 100%; left: 120px">-->
      <img src="@/assets/images/login/lighting.png" style="width: 40px; position: absolute; margin-top: -60px; margin-left: -10px">
      <div style="font-size: 40px; color: #0e0d0d;margin-bottom: 20px;font-weight: bold">欢迎登录</div>
      <div style="font-size: 16px; color: gray;margin-bottom: 50px;font-weight: normal">请输入账号和密码进行登录</div>
      <el-input :autofocus="true" style="margin-bottom: 20px;" v-model="name" placeholder="用户名"><i slot="prefix" class="el-input__icon el-icon-user"></i></el-input>
      <el-input style="margin-bottom: 20px;" v-model="password" placeholder="密码" show-password><i slot="prefix" class="el-input__icon el-icon-lock"></i></el-input>
      <el-button @click="login" type="primary" style="width: 170px;height: 50px; background-color: #4C5DE8; border-radius: 100px; float: right;border-color: #4C5DE8">登&nbsp;&nbsp;录<img src="@/assets/images/login/right-arrow.png" style="width: 30px;height: 30px;border-radius: 30px;position: absolute; top: 264px; margin-left: 25px"></el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return ({
      name: '',
      password: ''
    })
  },
  mounted () {
    document.body.style.overflow = 'hidden'
    window.addEventListener('keydown', this.keyDown)
    // this.captchasrc = apiBaseURL + '/captcha?uuid=' + this.uuid
    var domain = document.domain
    this.webName = '聪听数智舆情平台'
    document.title = this.webName + ' - 欢迎登录'
  },
  methods: {
    login () {
      if (this.name === '') {
        this.$notify.error('用户名不得为空')
        return
      }
      if (this.password === '') {
        this.$notify.error('密码不得为空')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '登录中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, .5)'
      })
      try {
        this.$store.dispatch('user/login', {
          userInfo: { username: this.name, password: this.password, uuid: this.uuid, captcha: this.captcha },
          error: (res) => {
            if (res.code === 5001) {
              this.$notify.error('试用项目已到期！')
            } else {
              this.$notify.error(res.msg)
            }
            loading.close()
          },
          callback: () => {
            loading.close()
            if (window.localStorage.getItem('logout')) {
              window.localStorage.setItem('logout', '')
            }
            localStorage.setItem('ct_account', this.name)
            if (this.name !== 'admin' && this.name !== 'ctadmin') {
              // localStorage.removeItem(localStorage.getItem('ct_account') + 'doneFirstGuide')
              // localStorage.removeItem(localStorage.getItem('ct_account') + 'setAlwaysShowMS')
              // sessionStorage.removeItem(localStorage.getItem('ct_account') + 'currentMonitor')
              this.$router.push('/')
            } else {
              this.$router.push('/settings/project')
            }
          }
        })
      } catch (err) {
        console.log('123')
        console.log(err)
      }
    },
    keyDown (e) {
      if (e.keyCode === 13) {
        this.login()
      }
    }
  },
  destroyed () {
    window.removeEventListener('keydown', this.keyDown, false)
    document.body.style.overflow = 'auto'
  }
}
</script>

<style scoped>
  .body {
    background-color: #A9C1FF; width: 100%; height: 100vh; overflow: hidden;
  }
  .animation-target {
    -webkit-animation: animation 1000ms linear both;
    animation: animation 1000ms linear both;
  }

  /* Generated with Bounce.js. Edit at http://bouncejs.com#%7Bs%3A%5B%7BT%3A%22t%22%2Ce%3A%22b%22%2Cd%3A1000%2CD%3A0%2Cf%3A%7Bx%3A1000%2Cy%3A0%7D%2Ct%3A%7Bx%3A0%2Cy%3A0%7D%2Cs%3A1%2Cb%3A2%7D%5D%7D */

  @-webkit-keyframes animation {
    0% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1000, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1000, 0, 0, 1); }
    7.61% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 463.737, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 463.737, 0, 0, 1); }
    11.41% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 262.369, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 262.369, 0, 0, 1); }
    15.12% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 118.67, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 118.67, 0, 0, 1); }
    18.92% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 20.136, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 20.136, 0, 0, 1); }
    22.72% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -37.738, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -37.738, 0, 0, 1); }
    30.23% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -72.345, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -72.345, 0, 0, 1); }
    50.25% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -15.212, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -15.212, 0, 0, 1); }
    70.27% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.461, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.461, 0, 0, 1); }
    100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  }

  @keyframes animation {
    0% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1000, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1000, 0, 0, 1); }
    7.61% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 463.737, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 463.737, 0, 0, 1); }
    11.41% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 262.369, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 262.369, 0, 0, 1); }
    15.12% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 118.67, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 118.67, 0, 0, 1); }
    18.92% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 20.136, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 20.136, 0, 0, 1); }
    22.72% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -37.738, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -37.738, 0, 0, 1); }
    30.23% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -72.345, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -72.345, 0, 0, 1); }
    50.25% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -15.212, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -15.212, 0, 0, 1); }
    70.27% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.461, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.461, 0, 0, 1); }
    100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  }
</style>
